document.addEventListener('DOMContentLoaded', () => {
    const slides = document.querySelectorAll('.slide');
    let currentIndex = 0;

    const showSlide = (index) => {
        slides.forEach((slide, i) => {
            const video = slide.querySelector('video');
            if (i === index) {
                slide.style.zIndex = 2; // Bring the current slide to the front
                slide.classList.add('active');
                slide.style.visibility = 'visible'; // Make the current slide visible
                if (video) {
                    video.currentTime = 0; // Reset video to the beginning
                    video.play();         // Play video
                }
            } else {
                slide.style.zIndex = 1; // Send the previous slide to the back
                slide.classList.remove('active');
                slide.style.visibility = 'hidden'; // Hide the previous slide
                if (video) {
                    video.pause();        // Pause video
                }
            }
        });
    };

    const rotateSlides = () => {
        const currentSlide = slides[currentIndex];
        const duration = parseInt(currentSlide.dataset.duration, 10) || 3000;

        setTimeout(() => {
            currentIndex = (currentIndex + 1) % slides.length; // Loop back to the first slide
            showSlide(currentIndex);
            rotateSlides();
        }, duration);
    };

    // Prefetch videos
    slides.forEach(slide => {
        const video = slide.querySelector('video');
        if (video) {
            video.muted = true;
            video.load();
        }
    });

    // Start rotation
    showSlide(currentIndex);
    rotateSlides();
});
